<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link :to="{ name: 'courseIntrosList' }">
            {{ $t("pageTitle.courseIntrosList") }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ helper.displayI18nText($i18n.locale, course.title) }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div>
      <h3>
        {{ helper.displayI18nText($i18n.locale, course.title) }}
        <router-link
          :to="{
            name: 'courseIntrosEdit',
            params: { id: $route.query.course_id }
          }"
        >
          <i class="fa fa-edit"></i>
        </router-link>
      </h3>
      <hr separate-line />
      <el-row :gutter="8">
        <el-card class="box-card">
          <el-form ref="form" label-width="180px">
            <el-form-item label="Course Title:">
              {{ helper.displayI18nText($i18n.locale, course.title) }}
            </el-form-item>
            <el-form-item label="Course Summary:">
              {{ helper.displayI18nText($i18n.locale, course.summary) }}
            </el-form-item>
            <el-form-item label="Course Content:">
              <div
                v-html="helper.displayI18nText($i18n.locale, course.content)"
              ></div>
            </el-form-item>
            <el-form-item label="Course Length:">
              {{ helper.displayI18nText($i18n.locale, course.length) }}
            </el-form-item>
            <el-form-item label="Course Prerequisite:">
              {{ helper.displayI18nText($i18n.locale, course.prerequisite) }}
            </el-form-item>
            <el-form-item label="Course Level:">
              {{ helper.displayI18nText($i18n.locale, course.level) }}
            </el-form-item>
            <el-form-item label="Course Materials:">
              {{ helper.displayI18nText($i18n.locale, course.materials) }}
            </el-form-item>
            <el-form-item label="Course Fee:">
              {{ course.fee }}TWD
            </el-form-item>
            <el-form-item label="Course Fee Description:">
              {{ helper.displayI18nText($i18n.locale, course.prerequisite) }}
            </el-form-item>
            <el-form-item label="Course Homework Workload:">
              {{
                helper.displayI18nText($i18n.locale, course.homework_workload)
              }}
            </el-form-item>
          </el-form>
        </el-card>
      </el-row>
    </div>
  </div>
</template>

<script>
import { helper } from "@ivy-way/material";
import courseApi from "@/apis/course";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  metaInfo() {
    return {
      title: "Course Sessions - Ivy-Way Academy"
    };
  },

  components: {
    Breadcrumb
  },

  props: [],
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      sessionList: [],
      pagedCourseSessionsList: [],
      inactiveCurrentPage: 1,
      inactiveSessionList: [],
      pagedInactiveCourseSessionsList: [],
      course: null,
      activeName: "activeList"
    };
  },
  computed: {
    helper() {
      return helper;
    },
    isActive() {
      return this.activeName === "activeList";
    }
  },
  watch: {},

  async created() {
    if (this.$route.query.activeTab) {
      this.activeName = this.$route.query.activeTab;
    }

    if (this.$route.query.activePage) {
      this.currentPage = Number(this.$route.query.activePage);
    }
    if (this.$route.query.inactivePage) {
      this.inactiveCurrentPage = Number(this.$route.query.inactivePage);
    }
    await this.initialSessionList();
  },

  methods: {
    async initialSessionList() {
      await this.loadCourse();
      if (this.$route.query.activeTab === "inActiveList") {
        await this.loadInactiveCourseSession();
      } else {
        await this.loadCourseSession();
      }
      this.handleCurrentChange();
      this.handleInactiveCurrentChange(this.inactiveCurrentPage);
    },
    async loadCourse() {
      if (this.$route.query.course_id) {
        let courseRes = await courseApi.fetchCourse(
          this.$route.query.course_id
        );
        this.course = courseRes.course;
      }
    },
    async loadCourseSession() {
      let queryParams = {
        course_id: this.$route.query.course_id,
        is_visible: 1
      };
      let sessionRes = await courseApi.fetchSessions(queryParams);
      this.sessionList = sessionRes.course_sessions;
    },
    async loadInactiveCourseSession() {
      let queryParams = {
        course_id: this.$route.query.course_id,
        is_visible: 0
      };
      let sessionRes = await courseApi.fetchSessions(queryParams);
      this.inactiveSessionList = sessionRes.course_sessions;
    },
    handleQueryChange(queries) {
      let routerQueries = { ...this.$route.query, ...queries };
      this.$router.push({
        name: "courseSessionsList",
        query: routerQueries
      });
    },
    handleCurrentPageChange(page) {
      if (this.activeName === "activeList") {
        this.handleQueryChange({ activePage: page });
      } else {
        this.handleQueryChange({ inactivePage: page });
      }
    },
    handleCurrentChange() {
      if (this.activeName === "activeList") {
        this.currentChangePage(this.sessionList, this.currentPage);
      } else {
        this.handleInactiveCurrentChange(this.inactiveCurrentPage);
      }
    },
    currentChangePage(list, currentPage) {
      let from = (currentPage - 1) * this.pageSize;
      let to = currentPage * this.pageSize;
      this.pagedCourseSessionsList = [];
      for (; from < to; from++) {
        if (list[from]) {
          this.pagedCourseSessionsList.push(list[from]);
        }
      }
    },

    handleInactiveCurrentChange(currentPage) {
      this.inactiveCurrentPage = currentPage;
      this.inactiveCurrentChangePage(this.inactiveSessionList, currentPage);
    },
    inactiveCurrentChangePage(list, currentPage) {
      let from = (currentPage - 1) * this.pageSize;
      let to = currentPage * this.pageSize;
      this.pagedInactiveCourseSessionsList = [];
      for (; from < to; from++) {
        if (list[from]) {
          this.pagedInactiveCourseSessionsList.push(list[from]);
        }
      }
    },

    createCourseSession() {
      this.$router.push({
        name: "courseSessionsCreate",
        query: {
          course_id: this.course ? this.course.id : 0
        }
      });
    },

    handleDelete(id) {
      return async () => {
        await courseApi.deleteSession(id);
        await this.loadCourseSession();
        await this.loadCourse();
        await this.loadCourseSession();
        await this.loadInactiveCourseSession();
        this.handleCurrentChange();
        this.handleInactiveCurrentChange(this.currentPage);
      };
    },
    async inactiveSession(id, session) {
      try {
        await courseApi.postSession(id, { ...session, is_visible: 0 });
        await this.initialSessionList();
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async activeSession(id, session) {
      try {
        await courseApi.postSession(id, { ...session, is_visible: 1 });
        await this.initialSessionList();
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style scoped></style>
